import { useEffect } from 'react'

import { Button, Card, CardBody, CardHeader, Heading, Text } from '@chakra-ui/react'

import { SimpleGrid } from '@chakra-ui/react'

import { Feature } from 'flagged'

import { AllowedDomainsForm } from './AllowedDomainsForm'
import MayhemBotCard from './MayhemBotCard'
import { SetAnnouncementForm } from './SetAnnouncementForm'
import { EnrollmentCard } from './EnrollmentCard'
import { SystemResourceQuotaForm } from './SystemResourceQuotaForm'

import { AdminDefaultWorkspaceForm } from './AdminDefaultWorkspaceForm'

import { useAppDispatch } from '@/hooks'
import { FEATURE_ADMIN_ANNOUNCEMENTS, FEATURE_DEFAULT_WORKSPACES } from '@/featureFlags'
import { getAdminSettingsPageTitle } from '@/titles'

export function AdminSettings() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    document.title = getAdminSettingsPageTitle('General')
  }, [dispatch])

  return (
    <SimpleGrid columns={{ base: 1, xl: 2 }} spacing={8}>
      <EnrollmentCard />
      <Card h="100%">
        <CardHeader>
          <Heading variant="cardHeading">Audit Log</Heading>
        </CardHeader>
        <CardBody p={8}>
          <Text>This .csv file contains a list of settings changes made by Mayhem Admins.</Text>
          <br />
          <a href="/api/v2/audit/audit_events.csv" download>
            <Button variant="solid">Download Audit Log (.csv)</Button>
          </a>
        </CardBody>
      </Card>
      <Feature name={FEATURE_DEFAULT_WORKSPACES}>
        <Card overflow="visible">
          <CardHeader>
            <Heading variant="cardHeading">Workspaces</Heading>
          </CardHeader>
          <CardBody p={8}>
            <AdminDefaultWorkspaceForm />
          </CardBody>
        </Card>
      </Feature>
      <Card>
        <CardHeader>
          <Heading variant="cardHeading">Allowed Domains</Heading>
        </CardHeader>
        <CardBody p={8}>
          <AllowedDomainsForm />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <Heading variant="cardHeading">Resource Limits</Heading>
        </CardHeader>
        <CardBody p={8}>
          <SystemResourceQuotaForm />
        </CardBody>
      </Card>
      <MayhemBotCard />
      <Feature name={FEATURE_ADMIN_ANNOUNCEMENTS}>
        <Card>
          <CardHeader>
            <Heading variant="cardHeading">Announcement</Heading>
          </CardHeader>
          <CardBody p={8}>
            <SetAnnouncementForm />
          </CardBody>
        </Card>
      </Feature>
    </SimpleGrid>
  )
}
