import { Button, FormControl, FormLabel, HStack, Switch, useDisclosure, VStack, Text } from '@chakra-ui/react'

import ListingTable from '../../components/ListingTable/ListingTable'
import { TableCard } from '../../components/Card/TableCard'
import { SearchBar } from '../../components/SearchBar'

import { Registry } from '../registry-management/utils'
import { getRegistriesColumns } from '../project-registries/ProjectRegistriesPage'

export function AdminRegistries() {
  const items: Registry[] = [
    {
      name: 'Docker Hub',
      endpointURL: 'https://hub.docker.com',
      provider: 'Docker',
      usedBy: ['Mayhem'],
      workspaceSlug: 'mayhem',
      projectSlug: 'mayhem',
      registryID: 0
    },
    {
      name: 'GitHub Packages',
      endpointURL: 'https://ghcr.io',
      provider: 'GitHub',
      usedBy: ['Mayhem'],
      workspaceSlug: 'mayhem',
      projectSlug: 'mayhem',
      registryID: 1
    }
  ]
  const { onOpen } = useDisclosure()

  return (
    <TableCard
      title="Registries"
      headerActions={
        <HStack>
          <FormControl display="flex" alignItems="center" gap="2">
            <Switch id="registry-permissions" />
            <FormLabel marginBottom="0" htmlFor="registry-permissions">
              Allow teams to connect to any/all registries
            </FormLabel>
            <Button onClick={onOpen}>Add New Registry</Button>
            {/* TODO refactor logic for handling allowed registries list */}
            {/* <RegistryModal isOpen={isOpen} onClose={onClose} /> */}
          </FormControl>
        </HStack>
      }
      actions={
        <VStack width="full" alignItems="flex-start" gap="4">
          <Text>These are the external registries that Mayhem can pull images from.</Text>
          <SearchBar placeholder="Search for a registry" InputGroupProps={{ flex: '1 1' }} />
        </VStack>
      }
    >
      <ListingTable
        rowKey={(row) => row.name}
        itemName="registry"
        items={items}
        isLoading={false}
        isError={false}
        columns={getRegistriesColumns()}
        count={items.length}
      />
    </TableCard>
  )
}
