import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['internal'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getMetricsReport: build.query<GetMetricsReportApiResponse, GetMetricsReportApiArg>({
        query: (queryArg) => ({ url: `/api/v2/metrics-dashboard`, params: { time: queryArg.time } }),
        providesTags: ['internal']
      }),
      getUsersAdminMode: build.query<GetUsersAdminModeApiResponse, GetUsersAdminModeApiArg>({
        query: (queryArg) => ({ url: `/api/v2/user`, params: { offset: queryArg.offset, per_page: queryArg.perPage, q: queryArg.q } }),
        providesTags: ['internal']
      }),
      postUserAdminMode: build.mutation<PostUserAdminModeApiResponse, PostUserAdminModeApiArg>({
        query: (queryArg) => ({ url: `/api/v2/admin/add-user`, method: 'POST', body: queryArg.adminAddUser }),
        invalidatesTags: ['internal']
      }),
      getUserStats: build.query<GetUserStatsApiResponse, GetUserStatsApiArg>({
        query: () => ({ url: `/api/v2/user-stat` }),
        providesTags: ['internal']
      }),
      putUserAdminState: build.mutation<PutUserAdminStateApiResponse, PutUserAdminStateApiArg>({
        query: (queryArg) => ({ url: `/api/v2/user/${queryArg.userSlug}/admin`, method: 'PUT', body: queryArg.adminState }),
        invalidatesTags: ['internal']
      }),
      becomeUser: build.mutation<BecomeUserApiResponse, BecomeUserApiArg>({
        query: (queryArg) => ({ url: `/api/v2/admin/become`, method: 'POST', body: queryArg.becomeUser }),
        invalidatesTags: ['internal']
      }),
      postGenerateInvite: build.mutation<PostGenerateInviteApiResponse, PostGenerateInviteApiArg>({
        query: (queryArg) => ({ url: `/api/v2/admin/generate-invite`, method: 'POST', body: queryArg.adminSendInvite }),
        invalidatesTags: ['internal']
      }),
      postAdminGetInvite: build.mutation<PostAdminGetInviteApiResponse, PostAdminGetInviteApiArg>({
        query: (queryArg) => ({ url: `/api/v2/admin/get-invite`, method: 'POST', body: queryArg.adminGetInvite }),
        invalidatesTags: ['internal']
      }),
      postRevokeInvite: build.mutation<PostRevokeInviteApiResponse, PostRevokeInviteApiArg>({
        query: (queryArg) => ({ url: `/api/v2/admin/revoke-invite`, method: 'POST', body: queryArg.adminRevokeInvite })
      }),
      postStoreAnnouncement: build.mutation<PostStoreAnnouncementApiResponse, PostStoreAnnouncementApiArg>({
        query: (queryArg) => ({ url: `/api/v2/admin/announcement/store`, method: 'POST', body: queryArg.adminStoreAnnouncement }),
        invalidatesTags: ['internal']
      }),
      postClearAnnouncement: build.mutation<PostClearAnnouncementApiResponse, PostClearAnnouncementApiArg>({
        query: (queryArg) => ({ url: `/api/v2/admin/announcement/clear`, method: 'POST', body: queryArg.adminClearAnnouncement }),
        invalidatesTags: ['internal']
      }),
      getAnnouncement: build.query<GetAnnouncementApiResponse, GetAnnouncementApiArg>({
        query: () => ({ url: `/api/v2/announcement/` }),
        providesTags: ['internal']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetMetricsReportApiResponse = /** status 200 Retrieve a MetricsReport for the given time bucket. */ MetricsReport
export type GetMetricsReportApiArg = {
  time: 'today' | 'yesterday' | 'this_week' | 'last_week' | 'this_month' | 'last_month' | 'last_quarter' | 'this_year' | 'last_year' | 'all_time'
}
export type GetUsersAdminModeApiResponse = /** status 200 Search for users - including emails (available to admin only) */ Users
export type GetUsersAdminModeApiArg = {
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
  q?: string
}
export type PostUserAdminModeApiResponse = /** status 200 Creation of a user account by an admin */ User
export type PostUserAdminModeApiArg = {
  adminAddUser: AdminAddUser
}
export type GetUserStatsApiResponse = /** status 200
    Returns count of accounts.
    Admins can only see this
     */ UserStats
export type GetUserStatsApiArg = void
export type PutUserAdminStateApiResponse = /** status 200 Set the admin state of a user.

    Logged-in user must be an admin.
    The user to be modified must not be the logged-in user.
    (An admin cannot remove its own admin state.)

    :param user_slug: the user_slug of the user to modify

    :returns: the modified user object as a response
     */ User
export type PutUserAdminStateApiArg = {
  userSlug: string
  adminState: AdminState
}
export type BecomeUserApiResponse = /** status 200 Become the user with the specified slug */ Ok
export type BecomeUserApiArg = {
  becomeUser: BecomeUser
}
export type PostGenerateInviteApiResponse = /** status 200 Generate a single user invite. Only available to admins */ AdminGetInviteResponse
export type PostGenerateInviteApiArg = {
  adminSendInvite: AdminSendInvite
}
export type PostAdminGetInviteApiResponse = /** status 200 Get the invite associated with an email. Only available to admins */ AdminGetInviteResponse
export type PostAdminGetInviteApiArg = {
  adminGetInvite: AdminGetInvite
}
export type PostRevokeInviteApiResponse = /** status 200 OK */ BaseSchemaWithoutTimestamps
export type PostRevokeInviteApiArg = {
  adminRevokeInvite: AdminRevokeInvite
}
export type PostStoreAnnouncementApiResponse = /** status 200 persist an announcement */ AdminGetInviteResponse
export type PostStoreAnnouncementApiArg = {
  adminStoreAnnouncement: AdminStoreAnnouncement
}
export type PostClearAnnouncementApiResponse = /** status 200 clear currently persisted announcement */ AdminGetInviteResponse
export type PostClearAnnouncementApiArg = {
  adminClearAnnouncement: AdminClearAnnouncement
}
export type GetAnnouncementApiResponse = /** status 200 get currently persisted announcement */ AdminGetAnnouncementResponse
export type GetAnnouncementApiArg = void
export type OtherStats = {
  elapsed_cpu_seconds: number
  average_cpu_usage: number
  average_memory_usage: number
  n_new_testcases: number
  n_new_defective_testcases: number
  n_new_crashing_testcases: number
  n_new_users: number
  n_new_projects: number
  n_active_projects: number
  n_total_projects: number
  n_new_targets: number
  n_active_targets: number
  n_total_targets: number
  n_new_runs: number
  n_active_runs: number
  n_total_runs: number
  n_new_defects: number
  n_total_defects: number
}
export type DefectByType = {
  label: string
  value: number
}
export type TargetByLanguage = {
  label: string
  value: number
}
export type TopProject = {
  owner: string
  project_slug: string
  seconds: number
}
export type TopTarget = {
  owner: string
  project_slug: string
  target_slug: string
  seconds: number
}
export type MetricsReport = {
  created_at?: string
  updated_at?: string
  start_time: string
  end_time: string
  other_stats: OtherStats
  defects_by_type: DefectByType[]
  targets_by_language: TargetByLanguage[]
  top_projects: TopProject[]
  top_targets: TopTarget[]
}
export type ApiError = {
  message?: string
}
export type AccountNotificationPreferencesV1 = {
  weekly_project_summaries?: boolean
  free_events_and_training?: boolean
  daily_defect_updates?: boolean
}
export type AccountNotificationPreferences = {
  V1: AccountNotificationPreferencesV1
}
export type User = {
  created_at?: string
  updated_at?: string
  user_id?: number
  slug: string
  owner?: string
  last_login_at?: string | null
  last_activity?: string | null
  is_admin?: boolean
  active?: boolean
  email_verified?: boolean
  is_moderator?: boolean
  email_address?: string | null
  has_oauth_login?: boolean
  has_atlassian_auth?: boolean
  invite_date?: string | null
  invited_by?: string | null
  invite_status?: string | null
  max_active_runs?: number
  max_run_duration?: number
  username: string
  first_name?: string | null
  last_name?: string | null
  location?: string | null
  language?: string | null
  avatar?: string | null
  default_workspace_slug?: string | null
  last_seen_onboarding_version?: number | null
  last_seen_onboarding_at?: string | null
  notification_preferences?: AccountNotificationPreferences
  status: 'Invite Accepted' | 'Invite Revoked' | 'Invite Expired' | 'Invited' | 'Active' | 'Inactive' | 'Pending Verification'
}
export type Users = {
  created_at?: string
  updated_at?: string
  users?: User[]
  count?: number
}
export type AdminAddUser = {
  email: string
  username: string
  first_name?: string
  last_name?: string
  set_password?: boolean
}
export type UserStats = {
  total_count?: number
}
export type AdminState = {
  created_at?: string
  updated_at?: string
  is_admin?: boolean
  active?: boolean
}
export type Ok = {
  result?: string
}
export type BecomeUser = {
  slug: string
}
export type AdminGetInviteResponse = {
  success: string
}
export type AdminSendInvite = {
  email: string
}
export type AdminGetInvite = {
  email: string
}
export type BaseSchemaWithoutTimestamps = {}
export type AdminRevokeInvite = {
  email: string
}
export type AdminStoreAnnouncement = {
  announcement: string
}
export type AdminClearAnnouncement = {}
export type AdminGetAnnouncementResponse = {
  success?: string
}
