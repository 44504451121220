import { useCombobox, UseComboboxStateChange } from 'downshift'
import {
  List,
  ListItem,
  ListItemProps,
  ListProps,
  StylesProvider,
  forwardRef,
  useMultiStyleConfig,
  useStyles,
  InputProps,
  Box,
  SkeletonText,
  Alert,
  AlertIcon,
  BoxProps,
  Portal,
  Text
} from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import debounce from 'lodash.debounce'

import { groupBy } from '../../util/groupBy'

type ComboboxListProps = { isOpen: boolean } & ListProps
const ComboboxList = forwardRef<ComboboxListProps, 'ul'>(({ isOpen, ...props }, ref) => {
  const styles = useStyles()
  return <List display={isOpen ? 'block' : 'none'} sx={styles.list} {...props} ref={ref} />
})
ComboboxList.displayName = 'ComboboxList'

const Progress = forwardRef<BoxProps, 'div'>(({ ...props }, ref) => {
  const styles = useStyles()
  return <Box sx={styles.progress} {...props} ref={ref} />
})
Progress.displayName = 'Progress'

const ErrorMessage = forwardRef<BoxProps, 'div'>(({ ...props }, ref) => {
  const styles = useStyles()
  return <Box sx={styles.errorMessage} {...props} ref={ref} />
})
ErrorMessage.displayName = 'ErrorMessage'

type ComboboxItemProps = { itemIndex: number; highlightedIndex: number } & ListItemProps
const ComboboxItem = forwardRef<ComboboxItemProps, 'li'>(({ itemIndex, highlightedIndex, ...props }, ref) => {
  const isActive = itemIndex === highlightedIndex
  const styles = useStyles()

  return <ListItem data-highlighted={isActive ? true : undefined} className={isActive ? 'active' : undefined} sx={styles.item} {...props} ref={ref} />
})
ComboboxItem.displayName = 'ComboboxItem'

export type SearchProps<Item> = {
  /** The number of milliseconds to debounce input changes by.  Defaults to 300ms. */
  debounceWait?: number
  /** A cancellable promise that takes a search string fetches combo box items. */
  fetchItems: (search: string | undefined, controller: AbortController) => Promise<Item[]>
  /** Initializes the input with a value when first rendered. */
  initialValue?: string | null
  /** Used to render the input text of an item after selection. */
  itemToString: (item: Item | null) => string
  /** A callback triggered when the search item selection updates. */
  onSelectedItemChange?: (item: Item | null) => void
  /** Renders the input component.  Usually this is a Chakra component such as Input or InputGroup. */
  renderInput: (params: InputProps) => JSX.Element
  /** Renders results. */
  renderComboBoxItem: (item: Item | null) => JSX.Element
  /** The item selected by the search. This puts the input into controlled mode.  Use with onSelectedItemChange. */
  selectedItem?: Item | null
  /** Function used to render the group title in case the search is grouped */
  renderGroupTitle?: (title: string) => JSX.Element
  /** They key in the data we want to group by in case there are multiple groups */
  groupByKey?: keyof Item
}

type FetchState = 'Initial' | 'Fetching' | 'Error' | 'Ready' | 'No Results'

function SearchInner<Item>(props: SearchProps<Item>, ref: React.ForwardedRef<HTMLInputElement>) {
  const {
    groupByKey,
    debounceWait = 300,
    fetchItems,
    initialValue,
    itemToString,
    onSelectedItemChange,
    selectedItem,
    renderInput,
    renderComboBoxItem,
    renderGroupTitle
  } = props

  if (debounceWait < 0) {
    throw Error('debounceWait must be >= 0')
  }

  const [inputItems, setInputItems] = useState<Item[]>([])
  const [abortController, setAbortController] = useState<AbortController | null>(null)
  const [fetchState, setFetchState] = useState<FetchState>('Initial')

  let resetCombobox: () => void = () => {
    /* no op until useComboBox is called */
  }

  const styles = useMultiStyleConfig('Search', props)
  const debouncedFetch = useRef(
    debounce(({ inputValue }: UseComboboxStateChange<Item>) => {
      try {
        const newAbortController = new AbortController()
        setAbortController(newAbortController)
        fetchItems(inputValue, newAbortController)
          .then((items) => {
            setAbortController(null)
            setInputItems(items)
            if (items.length) {
              setFetchState('Ready')
            } else {
              setFetchState('No Results')
            }
          })
          .catch(() => setFetchState('Error'))
      } catch (err) {
        setFetchState('Error')
      }
    }, debounceWait)
  ).current

  const handleInputValueChange = (change: UseComboboxStateChange<Item>) => {
    if (!change.inputValue && change.selectedItem) {
      resetCombobox()
    }
    setFetchState('Fetching')
    setInputItems([])

    if (abortController) {
      abortController.abort()
    }

    debouncedFetch(change)
  }

  const groupItems = (items: Item[]) => {
    if (!groupByKey) {
      return { empty: items }
    }

    const grouped = groupBy(items, (i) => i[groupByKey] as string)
    return grouped
  }

  const flattenGroupedItems = (grouped: Record<string, Item[]>) => {
    const flat = []
    for (const key in grouped) {
      flat.push(...grouped[key])
    }
    return flat
  }
  const groupedItems = groupItems(inputItems)
  const flattenedGrouped = flattenGroupedItems(groupedItems)

  const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps, reset } = useCombobox({
    selectedItem: selectedItem,
    initialInputValue: initialValue || (selectedItem ? itemToString(selectedItem) : ''),
    items: flattenedGrouped,
    itemToString: itemToString,
    onInputValueChange: handleInputValueChange,
    onSelectedItemChange: (changes) => onSelectedItemChange && onSelectedItemChange(changes.selectedItem || null)
  })

  // bind reset handler
  // must do this here since it is called by handleInputValueChange, which is passed
  // to useCombobox
  resetCombobox = () => reset()

  const renderItem = renderComboBoxItem
  const inputProps = {
    ...styles.input,
    ...getInputProps({
      // forward the ref, allowing the search to work with react-hook-form
      ref,
      onChange: (e) => {
        if ((e.target as HTMLInputElement).value === '') {
          reset()
          setFetchState('Initial')
        }
      }
    })
  }
  const input = renderInput(inputProps as InputProps)

  // The popups are rendered in a Portal component to avoid being cropped by parent elements.
  // The position of the popup then has to be computed relative to the <body> element.
  const inputRef = useRef<HTMLDivElement>(null)
  const inputRect = inputRef.current?.getBoundingClientRect() // Returns the position
  const popupTop = `${(inputRect?.top || 0) + (inputRect?.height || 0)}px`
  const popupLeft = `${inputRect?.left || 0}px`

  const listComponents = []
  let index = 0
  for (const group in groupedItems) {
    listComponents.push(
      <Box key={group}>
        {groupByKey && <ListItem>{renderGroupTitle ? renderGroupTitle(group) : <Text fontWeight="bold">{group}</Text>}</ListItem>}
        {groupedItems[group].map((item) => {
          const jsxItem = (
            <ComboboxItem {...getItemProps({ item, index })} itemIndex={index} highlightedIndex={highlightedIndex} key={index}>
              {renderItem(item)}
            </ComboboxItem>
          )
          index++
          return jsxItem
        })}
      </Box>
    )
  }
  return (
    <StylesProvider value={styles}>
      <Box>
        <Box ref={inputRef}>{input}</Box>
        <Portal>
          <ComboboxList
            isOpen={isOpen && fetchState === 'Ready'}
            flex={1}
            overflowY="auto"
            flexDirection="column"
            data-testid="dropdown-list"
            top={popupTop}
            left={popupLeft}
            // suppress the ref error from downshift
            // that occurs because the menu is inside the Portal element
            {...getMenuProps({}, { suppressRefError: true })}
          >
            {listComponents}
          </ComboboxList>
          {isOpen && fetchState === 'Fetching' && (
            <Progress data-testid="progress" top={popupTop} left={popupLeft}>
              <SkeletonText noOfLines={4} spacing={4} />
            </Progress>
          )}
          {fetchState === 'Error' && (
            <ErrorMessage data-testid="error-message" top={popupTop} left={popupLeft}>
              <Alert status="error">
                <AlertIcon />
                Something went wrong. Try again!
              </Alert>
            </ErrorMessage>
          )}
        </Portal>
      </Box>
    </StylesProvider>
  )
}

// export const Search = React.forwardRef(SearchInner)

export const Search = React.forwardRef(SearchInner) as <T>(
  props: SearchProps<T> & { ref?: React.ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof SearchInner>
