import { ChakraProvider, ColorModeScript, extendTheme, ThemeConfig } from '@chakra-ui/react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { FlagsProvider } from 'flagged'

import { App } from './App'
import store, { persistor } from './redux/store'
import { theme } from './theming'
import { FEATURE_NOTIFICATION_SETTINGS, FEATURE_ADMIN_ANNOUNCEMENTS, FEATURE_LIFECYCLE_MANAGEMENT, FEATURE_DEFAULT_WORKSPACES } from './featureFlags'

const colorTheme = localStorage.getItem('theme') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

document.documentElement.setAttribute('data-theme', colorTheme)
localStorage.setItem('theme', colorTheme)
localStorage.setItem('chakra-ui-color-mode', colorTheme) // without this the Chakra inline styles cause some issues
const featureNotificationSettings = JSON.parse(localStorage.getItem(FEATURE_NOTIFICATION_SETTINGS) || 'false')
const featureAdminAnnouncements = JSON.parse(localStorage.getItem(FEATURE_ADMIN_ANNOUNCEMENTS) || 'false')
const featureLifecycleManagement = JSON.parse(localStorage.getItem(FEATURE_LIFECYCLE_MANAGEMENT) || 'false')
const featureDefaultWorkspaces = JSON.parse(localStorage.getItem(FEATURE_DEFAULT_WORKSPACES) || 'false')

const config: ThemeConfig = {
  initialColorMode: colorTheme as 'dark' | 'light',
  // once Semantic UI and other custom styles are gone could try to use this
  useSystemColorMode: false
}

const mayhemTheme = extendTheme({ config }, theme)

export default function AppContainer(): JSX.Element {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={mayhemTheme}>
          <ColorModeScript initialColorMode={mayhemTheme.config.initialColorMode} />
          <FlagsProvider
            features={{
              [FEATURE_NOTIFICATION_SETTINGS]: featureNotificationSettings,
              [FEATURE_ADMIN_ANNOUNCEMENTS]: featureAdminAnnouncements,
              [FEATURE_LIFECYCLE_MANAGEMENT]: featureLifecycleManagement,
              [FEATURE_DEFAULT_WORKSPACES]: featureDefaultWorkspaces
            }}
          >
            <App />
          </FlagsProvider>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  )
}
